<template>
  <div :class="widgetClasses" class="card" style="border-radius: 2.5rem">
    <!--begin::Table of occurences-->
    <div class="card-body my-2 mx-0 py-3 px-0">
      <div class="table-responsive fixTableHead">
        <!--begin::Table-->
        <table class="table table-row-gray-500 gy-5 gs-5 mb-0">
          <!--begin::Table head-->
          <thead id="table-head">
            <tr class="fw-bolder text-muted">
              <th class="text-dark fw-bolder" style="min-width: 200px !important;">Artigo</th>
              <th class="text-dark fw-bolder">Referência</th>
              <th class="text-dark fw-bolder">Quantidade</th>
            </tr>
          </thead>
          <!--end::Table head-->

          <!--begin::Table body-->
          <tbody class="fs-6 text-muted">
            <template v-for="(item, index) in occurenceLines" :key="index">
              <tr>
                <td>
                  {{ item.designacao }}
                </td>
                <td>{{ item.referencia }}</td>
                <td>
                  {{ item.quantidade }}
                </td>
              </tr>
            </template>
          </tbody>
          <!--end::Table body-->
        </table>
        <!--end::Table-->
      </div>
      <!--end::Table of occurences-->
    </div>
  </div>
</template>


<script>
export default {
  name: "OccurenceDetailTable",
  components: {},

  props: {
    occurenceLines: Object,
    widgetClasses: String,
  },
  methods: {

  },
};
</script>

<style scoped>
th {
  color: white !important;
  text-align: -webkit-center;
}

td {
  text-align: -webkit-center;
  border-bottom: 1px dotted #a1a5b7;
}

#table-head {
  background-color: #791b1d;
}
</style>