
import {defineComponent} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import FormBodyProduct from "@/components/occurrences/FormBodyProduct.vue";
import store from "@/store";
import FieldErrorMessage from "@/components/global/FieldErrorMessage.vue";
import ApiService from "@/core/services/ApiService";
import {useRouter} from "vue-router";
import OccurenceDetailTable from "@/components/customTables/OccurenceDetailTable.vue";

const router = useRouter();
/* eslint-disable */
export default defineComponent({
  name: "Conclusion",
  components: {
    OccurenceDetailTable,
  },
  props: {
    incNmr: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      occurence: {},
      file: File,
    };
  },

  computed: {
    occStatusColors() {

      switch (this.occurence["fechado"]) {
        case true:
          return "background-color: #d9eecc; color: #3d9a01 !important";
        case false:
          return "background-color: #fbecd3; color: #eaa224 !important;";
        default:
          return "background-color: #fbecd3; color: #eaa224 !important";
      }
    },
  },
  methods: {

    /*
    * ----------------DATA MANIPULATION----------------
    */

    setfile(decodedFile) {
      this.file = decodedFile;
      //console.log(this.file)
    },

    /*
    * ------------------API FETCHING------------------
     */

    loadOccurence() {

      let queryString = `?NumDossier=${this.incNmr}`


      ApiService.get("orders", queryString)
          .then(({data}) => {
            this.occurence = data.data[0];

          })
          .catch(({data}) => {
            console.log(data);
          });
    },

    /*
    * ------------------EMITS------------------
    */

    /*
    * -------------------UTILS--------------------
    */

    getFormatedDate(date) {
      return new Date(date).toLocaleDateString('pt-pt').replaceAll('/', '.')
    },

    back() {
      this.$router.go(-1);
    },

    /*
    * -------------------STYLES-------------------
     */


  },
  mounted() {
    this.loadOccurence();
    setCurrentPageTitle("Detalhe Incidência");
  },
});
